<template>
  <div class="address-dialog">
    <div class="item">
      <div class="item-key">
        收件人
      </div>
      <input class="input" type="text" v-model="username" placeholder="收件人姓名">
    </div>
    <div class="item">
      <div class="item-key">
        新手机号
      </div>
      <input class="item-value input" type="text" v-model="mobile" placeholder="收件人手机号">
    </div>
    <div class="item">
      <div class="item-key">地址</div>
      <div class="address-select">
        <el-select v-model="selectProvince"
                   @change="provinceChange"
                   placeholder="省份"
        >
          <el-option
              v-for="(item,index) in areasList"
              :key="index"
              :value="index"
              :label="item.name"
          ></el-option>
        </el-select>
        <el-select v-model="selectCity"
                   @change="cityChange"
                   placeholder="市">
          <el-option
              v-for="(item,index) in cityList"
              :key="item.name"
              :value="index"
              :label="item.name"
          ></el-option>
        </el-select>
        <el-select v-model="selectDistrict"
                   placeholder="区">
          <el-option
              v-for="(item) in districtList"
              :key="item.name"
              :value="item.name"
          ></el-option>
        </el-select>
      </div>
    </div>
    <input type="text" class="detail-address input" v-model="address" placeholder="详细地址">
    <div class="button" @click="subMitAddress">确认</div>
  </div>
</template>

<script>
  import * as Api from '@/api/user/user'

  export default {
    name: 'Address',
    data () {
      return {
        address: '',
        username: '',
        mobile: '',
        selectProvince: '',
        selectCity: '',
        selectDistrict: '',
        areasList: [],
        cityList: [],
        districtList: [],
        province: '',
        city: '',
        district: ''
      }
    },
    mounted () {
      this.getArea()
      if (!this.editAddressData.name) {
        this.selectProvince = ''
        this.selectCity = ''
        this.selectDistrict = ''

      }
      if (this.dialogType === 2) {
        this.renderAddress()
      } else {
        this.clearForm()
      }
    },
    computed: {},
    props: {
      editAddressData: {
        type: Object,
        default: function () {
          return {}
        }
      },
      dialogType: {
        type: Number,
        default: 1
      }
    },
    methods: {
      getArea () {
        Api.areas(res => {
          this.areasList = res
          this.cityList = this.areasList[0].children
          this.districtList = this.cityList[0].children
        })
      },
      renderAddress () {
        this.username = this.editAddressData.name
        this.mobile = this.editAddressData.mobile
        this.address = this.editAddressData.address
        let districtArr = this.editAddressData.district.split(' ')
        this.province = districtArr[0]
        this.selectProvince = this.findId(this.province, this.areasList)
        this.city = districtArr[1]
        this.provinceChange(this.selectProvince)
        this.selectCity = this.findId(this.city, this.cityList)
        this.district = districtArr[2]
        this.cityChange(this.selectCity)
        this.selectDistrict = districtArr[2]
      },
      provinceChange (id) {
        this.cityList = this.areasList[id].children
        this.province = this.areasList[id].name
      },
      cityChange (id) {
        this.districtList = this.cityList[id].children
        this.city = this.cityList[id].name
      },
      subMitAddress () {
        let reg = /^1\d{10}$/
        if (this.username === '') {
          return this.$message.warning('收件人姓名不能为空')
        } else if (!reg.test(this.mobile)) {
          return this.$message.warning('收件人手机号不正确')
        } else if (this.province === '' || this.city === '' || this.selectDistrict === '') {
          return this.$message.warning('请选择所在地区')
        } else if (this.address === '') {
          return this.$message.warning('详细地址不能为空')
        }
        let params = {
          address: this.address,
          district: this.province + ' ' + this.city + ' ' + this.selectDistrict,
          mobile: this.mobile,
          name: this.username,
          is_default: this.editAddressData.is_default === true ? 1 : 0
        }
        if (this.dialogType === 1) {
          Api.address('post', params, () => {
            this.closeDialog()
          })
        } else {
          Api.address('put', params, () => {
            this.closeDialog()
          }, '', this.editAddressData.id)
        }
      },
      closeDialog () {
        this.$emit('closeDialog')
      },
      findId (name, arr) {
        let id = ''
        arr.forEach((ele, index) => {
          if (ele.name == name) {
            id = index
          }
        })
        return id
      },
      clearForm () {
        this.username = ''
        this.mobile = ''
        this.address = ''
        this.province = ''
        this.city = ''
        this.district = ''
        this.selectCity = ''
        this.selectProvince = ''
        this.selectDistrict = ''
      }
    },
    watch: {
      editAddressData (newVal, oldVal) {
        if (newVal && newVal.name) {
          this.renderAddress()
        } else {

        }
      },
      selectProvince (newVal, oldVal) {
        if (newVal) {
          this.provinceChange(newVal)
        }
      },
      dialogType (newVal, odlVal) {
        if (newVal === 1) {
          this.clearForm()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .address-dialog {
    width: 100%;

    & > .item {
      width: 100%;
      text-align: left;
      margin-bottom: 26px;

      & > .item-key {
        font-size: 14px;
        color: #333;
        font-weight: bold;
        margin-bottom: 16px;
      }

      & > .input {
        width: 100%;
        border-bottom: 1px solid #E6E6E6;
        font-size: 14px;
        padding: 10px 0;
      }

      & > .address-select {
        display: flex;

        /deep/ .el-select {
          width: 80px;
          margin-right: 30px;

          .el-input {
            input {
              border: none;
              padding-left: 0;
            }
          }
        }
      }
    }

    & > .detail-address {
      margin-bottom: 30px;
      width: 100%;
      border-bottom: 1px solid #E6E6E6;
      font-size: 14px;
      padding: 10px 0;
    }

    & > .button {
      margin: 0 auto;
      width: 160px;
      height: 40px;
      background: #29D087;
      border-radius: 3px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
</style>
