import * as PayApi from '@/api/pay/pay.js'

export default class Pay {
  constructor (type, params) {
    this.type = type
    this.params = params
  }

  pay (successCallback, cancelCallback, errorCallback) {
      this.params.pay_type=this.type
      PayApi.pay(this.params, successCallback, errorCallback)
  }
}
