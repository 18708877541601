import Fetch from '@/utils/fetch.js'


// 课程商城
const pay = (data, fn, errorFn, dataLevel) => {
  new Fetch('payment/pay', 'post', data, fn, errorFn, dataLevel)
}





export {
    pay
}
