import Fetch from '@/utils/fetch.js'
import { courseDetail } from '@/api/course/course'
import { address } from '@/api/user/user'

// 加入购物车
const shoppingCart = (type, data, fn, errorFn, id = '', version) => {
  new Fetch(`carts/${id}`, type, data, fn, errorFn, version)
}


// 创建订单
const createOrder = (data, fn, errorFn, version) => {
  new Fetch('orders', 'post', data, fn, errorFn, version)
}

// 获取支付金额
const payFee = (data, fn, errorFn, version) => {
  new Fetch('carts/pay_fee', 'post', data, fn, errorFn, version)
}

// 获取订单状态
let baseUrl = process.env.VUE_APP_BASE_URL.split('//')[1]
const orderStatus = `wss://${baseUrl}get_payment_status:9867`

// 立即购买
const buyNow = (data, fn, errorFn, version) => {
  new Fetch('course/orders', 'post', data, fn, errorFn, version)
}

export {
  shoppingCart,
  courseDetail,
  address,
  createOrder,
  orderStatus,
  payFee,
  buyNow
}
