import store from '@/store';
import * as Api from '@/api/cart/cart';
import { coupon } from '@/api/user/user';
import Pay from '@/utils/pay';
import QRCode from 'qrcodejs2';
import Address from '@/components/Address/Address';
import * as ApiAddress from '@/api/user/user';
import { ORDER_TYPE, PAY_TYPE } from '@/enums/API';
import { formatMoment } from '@/utils/utils';

export default {
  data() {
    return {
      addressList: [],
      chooseAddressId: '',
      chooseAddress: '',
      activeType: PAY_TYPE.ALIPAY,
      remarkMessage: '',
      mobile: '',
      weChat: '',
      chooseService: false,
      productId: [],
      qrcodeDialog: false,
      chooseCouponId: '',
      couponList: [],
      totalMoney: 0,
      addressDialog: false,
      editAddressData: {},
      addressDialogTitle: '新增地址',
      dialogType: 1,
      buyType: '',
      orderSn: '',
      websocketMsg: '',
      PAY_TYPE,
    };
  },
  components: {
    Address,
  },
  computed: {
    cartList() {
      return store.state.cart.selectProduct.length === 0
        ? JSON.parse(sessionStorage.selectCourse)
        : store.state.cart.selectProduct;
    },
    userMessage() {
      return store.state.user.userInfo;
    },
    needAddress() {
      let need = true;
      store.state.cart.selectProduct.forEach((ele) => {
        need = need && ele.course.is_delivery;
      });

      return need;
    },
    payType() {
      switch (this.activeType) {
        case this.PAY_TYPE.WECHAT:
          return '微信';
        case this.PAY_TYPE.ALIPAY:
          return '支付宝';
        default:
          return '微信';
      }
    },
  },
  mounted() {
    this.weChat = this.userMessage.we_chat;
    this.mobile = this.userMessage.username;
    this.getAddress();
    this.cartList.forEach((ele) => {
      this.productId.push(ele.id);
    });
    this.getCoupon(this.productId);
    this.totalMoney = this.calculateTotal();
    this.buyType = this.$route.query.type || 2;
  },
  methods: {
    getCoupon(ids) {
      coupon(ids, '', (res) => {
        res.forEach((coupon) => {
          coupon.expired_at = formatMoment(coupon.expired_at);
        });
        this.couponList = res;
      });
    },
    getAddress() {
      Api.address('get', '', (res) => {
        let arr1 = [];
        let arr2 = [];
        res.forEach((ele) => {
          if (ele.is_default) {
            arr1.push(ele);
            this.chooseAddressId = ele.id;
            this.chooseAddress = ele.district + ele.address;
          } else {
            arr2.push(ele);
          }
        });

        this.addressList = arr1.concat(arr2);
        if (arr1.length === 0) {
          if (arr2.length !== 0) {
            this.chooseAddressId = arr2[0].id;
            this.chooseAddress = arr2[0].district + arr2[0].address;
          }
        }
      });
    },
    getPayFee(id) {
      let params = {
        cart_ids: this.productId,
        coupon_id: id,
      };
      Api.payFee(
        params,
        (res) => {
          this.totalMoney = res.pay_fee;
        },
        ''
      );
    },
    chooseCoupon(item) {
      this.chooseCouponId = item.id;
      if (item.id === 0) {
        this.totalMoney = this.calculateTotal();
        return;
      }
      if (parseInt(this.$route.query.type) === 1) {
        // 1 立即购买  2购物车购买
        this.totalMoney =
          this.calculateTotal() * 1 - item.money * 1 > 0
            ? this.calculateTotal() * 1 - item.money * 1
            : 0;
        return;
      } else {
        this.getPayFee(item.id);
      }
    },
    changeAddress(item) {
      this.chooseAddressId = item.id;
      this.chooseAddress = item.district + item.address;
    },
    choosePayType(type) {
      this.activeType = type;
    },
    createOrder() {
      let self = this;
      if (this.chooseService && this.mobile && this.weChat) {
        if (this.needAddress) {
          if (this.chooseAddressId === '') {
            this.$message.error('请选择收货地址');
            return;
          }
        }
        if (this.buyType === 2) {
          let ids = [];
          this.cartList.forEach((ele) => {
            ids.push(ele.id);
          });
          let params = {
            cart_ids: ids,
            we_chat: this.weChat,
          };
          if (this.chooseCouponId) {
            params.coupon_id = this.chooseCouponId;
          }
          if (this.chooseAddressId) {
            params.delivery_address_id = this.chooseAddressId;
          } else {
            params.mobile = this.mobile;
          }
          Api.createOrder(params, (res) => {
            self.orderSn = res.order_sn;
            if (res.jump_payment) {
              let params = {
                order_sn: res.order_sn,
                platform: 'web',
                product: 'shop',
              };
              let pay = new Pay(this.activeType, params);
              pay.pay(
                (res) => {
                  self.qrCode(res.order_info);
                  self.qrcodeDialog = true;
                  self.initWS();
                  try {
                    this.wsInterval = setInterval(function () {
                      self.send();
                      let data = JSON.parse(self.websocketMsg);

                      switch (data.status) {
                        case 1:
                          console.log('未支付');
                          break;
                        case 2:
                          console.log('支付成功');
                          this.closeQrcode();
                          clearInterval(this.wsInterval);
                          break;
                        case 3:
                          console.log('订单不存在');
                          this.closeQrcode();
                          break;
                        default:
                          break;
                      }
                    }, 1000);
                  } catch (e) {
                    clearInterval(this.wsInterval);
                  }
                  console.log('success');
                },
                () => {
                  console.log('cancel');
                },
                (error) => {
                  console.log('error', error);
                }
              );
            } else {
              this.$router.push({
                path: '/user/course',
                query: {},
              });
            }
          });
        } else {
          let course = this.cartList[0];
          let params = {
            course_id: course.id,
            course_subject_id: course.course_subject.id,
            teacher_level_id: course.teacher_level.id,
            purchase_qty: course.buy_qty,
            coupon_id: this.chooseCouponId,
            we_chat: this.weChat,
          };
          if (this.chooseAddressId) {
            params.delivery_address_id = this.chooseAddressId;
          }
          if (!params.delivery_address_id) {
            params.mobile = this.mobile;
          }
          Api.buyNow(params, (res) => {
            if (res.status === 100) {
              let params = {
                order_sn: res.order_sn,
                order_type: ORDER_TYPE.COURSE,
              };
              let pay = new Pay(this.activeType, params);
              self.orderSn = res.order_sn;
              pay.pay(
                (res) => {
                  self.$refs.qrcodeBox.innerHTML = '';
                  self.qrCode(res.order_info);
                  self.qrcodeDialog = true;
                  self.initWS();
                  try {
                    this.wsInterval = setInterval(function () {
                      self.send();
                      let data = JSON.parse(self.websocketMsg);
                      if (data) {
                        switch (data.status) {
                          case 1:
                            console.log('未支付');
                            break;
                          case 2:
                            console.log('支付成功');
                            self.closeQrcode();
                            clearInterval(self.wsInterval);
                            break;
                          case 3:
                            console.log('订单不存在');
                            self.closeQrcode();
                            clearInterval(self.wsInterval);
                            break;
                          default:
                            break;
                        }
                      }
                    }, 1000);
                  } catch (e) {
                    clearInterval(this.wsInterval);
                  }
                  console.log('success');
                },
                () => {
                  console.log('cancel');
                },
                (error) => {
                  console.log('error', error);
                }
              );
            } else {
              this.$router.push({
                path: '/user/course',
                query: {},
              });
            }
          });
        }
      } else {
        if (!this.chooseService) {
          this.$message.error('请选择服务协议');
        } else if (!this.weChat) {
          this.$message.error('请填写微信号');
        } else if (!this.mobile) {
          this.$message.error('请填写手机号');
        }
        return;
      }
    },
    choosePact() {
      this.chooseService = !this.chooseService;
    },
    qrCode(text) {
      return new QRCode('qrcode', {
        text: text,
        width: 120,
        height: 120,
        colorDark: '#000000',
        colorLight: '#ffffff',
      });
    },
    closeQrcode(status) {
      let self = this;
      this.qrcodeDialog = false;
      this.socket.close();
      clearInterval(this.wsInterval);
      if (status === 2) {
        this.$router.push({
          path: '/user/course',
        });
      } else {
        this.$router.push({
          path: '/user/orders/pay',
          query: {
            order_sn: self.orderSn,
          },
        });
      }
    },
    initWS() {
      this.socket = new WebSocket(Api.orderStatus);
      this.socket.onopen = this.wsOpen;
      this.socket.onerror = this.wsError;
      this.socket.onmessage = this.wsMessage;
      this.socket.onclose = this.close;
    },
    wsOpen() {
      console.log('连接成功');
    },
    wsError() {
      console.log('连接错误');
    },
    wsMessage(msg) {
      this.websocketMsg = msg.data;
    },
    send() {
      let params = {
        order_sn: this.orderSn,
      };
      params = JSON.stringify(params);
      this.socket.send(params);
    },
    close() {
      this.socket = null;
      console.log('close');
    },
    calculateTotal() {
      let total = 0;
      let selectProduct = JSON.parse(sessionStorage.selectCourse);
      selectProduct.forEach((ele) => {
        total += ele.buy_qty * ele.course_attribute.teacher_level[0].unit_price;
      });
      return total;
    },
    addAddress() {
      this.dialogType = 1;
      this.addressDialogTitle = '新增地址';
      this.addressDialog = true;
      this.editAddressData = {};
    },
    editAddress(data) {
      this.dialogType = 2;
      this.addressDialogTitle = '修改地址';
      this.editAddressData = data;
      this.addressDialog = true;
    },
    deleteAddress(id) {
      ApiAddress.address(
        'delete',
        '',
        () => {
          this.$message.success('删除成功');
          this.getAddress();
        },
        '',
        id,
        ''
      );
    },
    changeDefault(item) {
      let params = { ...item };
      params.is_default = 1;
      ApiAddress.address(
        'put',
        params,
        () => {
          this.$message.success('修改成功');
          this.getAddress();
        },
        '',
        item.id
      );
    },
    closeDialog() {
      this.addressDialog = false;
      this.getAddress();
    },
  },
};
