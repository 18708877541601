<template>
  <div class="confirm-order-page">
    <div class="top-tip">订单已提交，请及时完成支付。付款后老师将为您安排课程。请在15分钟内完成支付。</div>
    <div class="section order-message">
      <div class="section-name">订单信息</div>
      <div class="course-list">
        <div class="course-item" v-for="item in cartList" :key="item.id">
          <div class="course-pic">
            <img :src=item.course.cover_image_url alt="">
            <div class="course-desc">
              <div class="course-name">{{item.course.title}}</div>
              <div class="course-subject">科目{{item.course_subject.name}}</div>
              <div class="course-teacher" v-if="item.teacher_level.name">教师{{item.teacher_level.name}}</div>
              <div class="course-price">价格{{item.course_attribute.teacher_level[0].unit_price*item.buy_qty}}元</div>
            </div>
          </div>
          <div class="course-qty">x{{item.buy_qty}}</div>
        </div>
      </div>
    </div>
    <div class="section user-message">
      <div class="section-name">购买信息</div>
      <div class="message-con">
        <div class="item">
          <div class="user-account key">
            购买账号
          </div>
          <div class="value">{{userMessage.username}}</div>
        </div>
        <div class="item" v-if="!needAddress">
          <div class="user-account key">
            手机号
          </div>
          <div class="value">
            <input type="text" v-model="mobile" placeholder="请填写手机号">
          </div>
        </div>
        <div class="item">
          <div class="user-account key">
            微信号
          </div>
          <div class="value">
            <input type="text" v-model="weChat" plactholder="请填写微信号">
          </div>
        </div>
      </div>
    </div>
    <div class="section address-message" v-show="needAddress">
      <div class="section-name">收货信息</div>
      <div class="address-list">
        <div class="address-item"
             v-for="item in addressList"
             :key="item.id"
             :class="chooseAddressId === item.id ? 'address-active': ''"
             @click.stop="changeAddress(item)">
          <div class="top">
            <div class="left">
              <div class="username">{{item.name}}</div>
              <div class="phone-number">{{item.mobile}}</div>
            </div>
            <div class="is-default" v-show="item.is_default">默认地址</div>
          </div>
          <div class="address-con">
            <div class="address-text">{{item.district}}{{item.address}}</div>
            <div class="action-box">
              <div class="change-button button" @click="editAddress(item)">修改</div>
              <div class="delete-button button" @click="deleteAddress(item.id)">删除</div>
            </div>
          </div>
          <i v-show="chooseAddressId === item.id" class="iconfont icon-papa-buy_angle_select"></i>
        </div>
        <div class="address-item add-address" @click="addAddress">
          <span class="add-icon">+</span>
          <span class="add-text">添加新地址</span>
        </div>
      </div>

    </div>
    <div class="section coupon-message" v-if="couponList.length>0">
      <div class="top">
        <div class="section-name">优惠券</div>
        <i class="iconfont icon-papa-right"></i>
      </div>
      <div class="coupon-list"  v-if="couponList.length>0">
        <div class="coupon-item" @click="chooseCoupon({id: 0})">
          <div class="coupon-desc" :class="chooseCouponId === 0? 'coupon-desc-select': ''">不使用优惠券</div>
          <i class="iconfont" :class="chooseCouponId === 0?'icon-papa-buy_selected':'icon-papa-buy_select_none'"></i>
        </div>
        <div class="coupon-item" v-for="item in couponList" :key="item.id" @click="chooseCoupon(item)">
          <div class="coupon-desc" :class="chooseCouponId === item.id? 'coupon-desc-select': ''">{{item.title}}（{{item.description}};过期时间:{{item.expired_at}}）</div>
          <i class="iconfont" :class="chooseCouponId === item.id?'icon-papa-buy_selected':'icon-papa-buy_select_none'"></i>
        </div>
      </div>
    </div>
<!--    <div class="section remark-message">-->
<!--      <div class="section-name">备注</div>-->
<!--      <textarea v-model="remarkMessage" class="remark" name="" id="" cols="30" rows=""-->
<!--                placeholder="有其它话对趴趴说"></textarea>-->
<!--    </div>-->
    <div class="service" @click="choosePact">
      <i class="iconfont" :class="chooseService?'icon-papa-buy_selected':'icon-papa-buy_select_none'"></i>
      <span>我已同意</span>
      <router-link to="/terms">
        <span class="service-page">《趴趴英语服务协议》</span>
      </router-link>
    </div>
    <div class="section page-type">
      <div class="section-name">支付方式</div>
      <div class="type-list">
        <div class="ali-pay type" :class="activeType === PAY_TYPE.ALIPAY?'type-active':''"
             @click="choosePayType(PAY_TYPE.ALIPAY)">
          <img src="../img/alipay.png" alt="">
          <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === 'aliPay'"></i>
        </div>
        <div class="wechat-pay type" :class="activeType === PAY_TYPE.WECHAT?'type-active':''"
             @click="choosePayType(PAY_TYPE.WECHAT)">
          <img src="../img/wechat.png" alt="">
          <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === 'wechatPay'"></i>
        </div>
<!--        <div class="jdpay type" :class="activeType === 'jdPay'?'type-active':''" @click="choosePayType('jdPay')">-->
<!--          <img src="../img/jdpay.png" alt="">-->
<!--          <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === 'jdPay'"></i>-->
<!--        </div>-->

      </div>
    </div>
    <div class="buy-box">
      <div class="price">实付款 <span>￥{{totalMoney}}</span></div>
      <div class="buy-button" :class="(chooseService&&mobile&&weChat)?'buy-button-active':''" @click="createOrder">确认支付
      </div>
    </div>
    <div class="pay-code" v-show="qrcodeDialog">
      <div class="header">
        <div class="title">{{payType}}支付</div>
        <div class="close-btn" @click="closeQrcode">x</div>
      </div>
      <div class="content">
        <div class="pay-number">
          <div class="key">应付金额</div>
          <div class="value">￥{{totalMoney}}</div>
        </div>
        <div id="qrcode" ref="qrcodeBox"></div>
      </div>
      <div class="footer">
        <div class="desc">使用{{payType}}扫描二维码完成支付</div>
      </div>
    </div>
    <div class="mask" v-show="qrcodeDialog" @click="closeQrcode"></div>
    <el-dialog
        :title="addressDialogTitle"
        :visible.sync="addressDialog"
        width="350px"
    >
      <Address @closeDialog="closeDialog" :editAddressData="editAddressData" :dialogType="dialogType"></Address>
    </el-dialog>
  </div>
</template>

<script>
  import ConfirmCartJs from './ConfirmCart.js'

  export default ConfirmCartJs
</script>

<style lang="less" scoped>
  .confirm-order-page {
    width: 1200px;
    background: #fff;
    margin: 30px auto 70px;
    box-sizing: border-box;
    padding: 30px 40px;
    overflow: hidden;

    & > .top-tip {
      width: 1120px;
      height: 36px;
      background: #fff8f8;
      color: #fe6f7e;
      font-size: 12px;
      line-height: 36px;
      text-align: left;
      padding: 0 10px;
      box-sizing: border-box;
    }

    & > .section {
      .section-name {
        text-align: left;
        font-size: 18px;
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        margin: 20px 0;
      }
    }

    & > .order-message {
      & > .course-list {
        & > .course-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          & > .course-pic {
            display: flex;

            & > img {
              width: 144px;
              height: 80px;
              margin-right: 15px;
            }

            & > .course-desc {
              text-align: left;
              color: #555;
              font-size: 14px;

              & > .course-name {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                margin-bottom: 8px;
              }

            }
          }
        }
      }
    }

    & > .user-message {
      & > .message-con {
        display: flex;

        & > .item {
          color: #555;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-right: 100px;

          & > .key {
            margin-right: 8px;
          }

          & > .value {
            font-weight: bold;

            & > input {
              border: none;
              outline: none;
              font-size: 14px;
              color: #555;
              font-weight: bold;
            }

            & > input::placeholder {
              font-size: 14px;
              color: #555;
            }
          }
        }
      }
    }

    & > .address-message {
      & > .address-list {
        overflow: hidden;

        & > .address-active {
          border: 1px solid rgba(41, 208, 135, 1) !important;

        }

        & > .address-item {
          float: left;
          width: 260px;
          height: 116px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid #e6e6e6;
          border-radius: 3px;
          padding: 0 15px;
          box-sizing: border-box;
          font-size: 14px;
          margin-right: 26px;
          text-align: left;
          position: relative;
          margin-bottom: 18px;

          & > .top {
            width: 100%;
            height: 38px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            line-height: 38px;
            font-weight: bold;
            justify-content: space-between;

            & > .left {
              display: flex;

              & > .username {
                max-width: 5em;
                min-width: 2em;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-right: 10px;
              }
            }

            .is-default {
              width: 4.5em;
              height: 16px;
              background: rgba(245, 245, 245, 1);
              font-size: 10px;
              color: #555;
              text-align: center;
              line-height: 16px;
              margin: 11px 0;
            }
          }

          & > .address-con {
            & > .address-text {
              text-align: left;
              padding: 10px 0 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              line-height: 1.3em;
            }

            & > .action-box {
              display: flex;
              position: absolute;
              bottom: 10px;

              & > .button {
                color: #29D087;
                font-size: 12px;
                cursor: pointer;
              }

              & > .change-button {
                margin-right: 10px;
              }
            }
          }

          & > .icon-papa-buy_angle_select {
            color: #29D087;
            position: absolute;
            right: -2px;
            bottom: -2px;
          }

          & > .icon-papa-buy_angle_select:before {
            margin-right: 0px;
          }
        }

        & > .address-item:nth-child(4) {
          margin-right: 0;
        }

        & > .add-address {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #999;

          & > .add-icon {
            font-size: 30px;
            font-weight: bold;
          }

          & > .add-text {
            font-size: 14px;
          }

        }
      }
    }

    & > .coupon-message {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      &>.top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &>.coupon-list{
        font-size: 14px;
        color: #333;
        width: 100%;
        &>.coupon-item{
          cursor: pointer;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          & > .icon-papa-buy_selected {
            color: #29D087;
          }
          &>.coupon-desc{
            color: #ccc;
          }
          &>.coupon-desc-select{
            color: #333!important;
          }
        }
      }

      & > a {
        color: #999;
        font-size: 14px;
      }
    }

    & > .remark-message {
      & > .remark {
        width: 100%;
        border: none;
        outline: none;
        background: #FAFAFA;
        padding: 18px;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #e6e6e6;
        color: #999999;
        font-size: 14px;

      }
    }

    & > .service {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      cursor: pointer;

      & > .icon-papa-buy_selected {
        color: #29D087;
      }

      & > a {
        & > .service-page {
          color: #29D087;
        }
      }
    }

    & > .page-type {
      margin-bottom: 50px;

      & > .type-list {
        display: flex;

        & > .type {
          cursor: pointer;
          width: 142px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
          position: relative;
          border: 1px solid transparent;
        }

        & > .type-active {
          border: 1px solid #29D087 !important;

          & > .icon-papa-buy_angle_select {
            position: absolute;
            bottom: -2px;
            right: -2px;
            color: #29D087;
          }

          & > .icon-papa-buy_angle_select:before {
            margin-right: 0;
          }
        }

        & > .ali-pay , .wechat-pay{
          margin-right: 50px;
        }
      }
    }

    & > .buy-box {
      float: right;

      & > .buy-button {
        width: 180px;
        height: 46px;
        background: #73797F;
        border-radius: 3px;
        color: #fff;
        text-align: center;
        line-height: 46px;
        font-size: 18px;
        cursor: pointer;
      }

      & > .buy-button-active {
        background: rgba(41, 208, 135, 1);
      }

      & > .price {
        font-size: 16px;
        color: #666;
        margin-bottom: 14px;
        font-weight: bold;

        & > span {
          color: #fe6f7e;
          font-size: 26px;
        }
      }
    }

    & > .pay-code {
      width: 280px;
      height: 300px;
      border-radius: 8px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -170px;
      margin-left: -140px;
      z-index: 10000;

      & > .header {
        width: 100%;
        height: 50px;
        font-size: 15px;
        line-height: 50px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        box-sizing: border-box;
        padding: 0 10px;

        & > .close-btn {
          cursor: pointer;
          width: 20px;
          height: 100%;
          font-weight: lighter;
          font-size: 16px;
        }
      }

      & > .content {
        & > .pay-number {
          display: flex;
          justify-content: center;
          margin: 10px 0;
          align-items: center;

          & > .value {
            text-align: left;
            font-size: 20px;
            color: red;
          }
        }

        & > #qrcode {
          position: absolute;
          top: 58%;
          left: 50%;
          margin-top: -60px;
          margin-left: -60px;
        }
      }

      & > .footer {
        & > .desc {
          width: 100%;
          position: absolute;
          bottom: 20px;
          margin-top: 10px;
          font-size: 12px;
          text-align: center !important;
        }
      }

    }

    & > .mask {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }
</style>
